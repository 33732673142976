<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Crear</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" label-width="100px" :model="form">
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="Titulo" rules="required|min:5|max:30" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción corta" size="mini">
                <ValidationProvider name="decription short" rules="required|min:1|max:65" v-slot="{ errors }">
                  <el-input v-model="form.description"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen*">
                <div v-if="form.image">
                  <el-image :src="$urlGlobalApi +form.image">
                    <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  drag
                  action=""
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb (800 x 600)</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Tipo" size="mini">
                <ValidationProvider name="tipo" rules="required" v-slot="{ errors }">
                  <el-select
                    v-on:change="handleSearch()"
                    clearable
                    size="mini"
                    v-model="form.type"
                    filterable
                    placeholder="Tipo">
                    <el-option
                      v-for="itemType in optionType"
                      :key="itemType.value"
                      :label="itemType.label"
                      :value="itemType.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>

              <!-- Texto -->
              <div v-if="form.type == 'text'" >
                <el-form-item label="Contenido">
                 <tinyeditor v-model="form.content"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
                </el-form-item>
                <el-form-item label="Cargar Archivo .pdf">
                  <el-upload
                    name="image"
                    action="#"
                    :http-request="postFile"
                    :before-upload="beforeFIleUpload"
                    :on-success="handleAvatarSuccessFile"
                    >
                    <el-button slot="trigger" size="small" type="primary">Selecciona un archivo .pdf</el-button>
                    <!-- <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Suelta tu Archivo aquí o <em>haz clic para cargar</em></div> -->
                  </el-upload>
                </el-form-item>
              </div>

              <!-- Video -->
              <div v-if="form.type == 'video'">
                <el-form-item label="Url de video">
                  <el-input size="mini" v-model="form.video"></el-input>
                </el-form-item>
              </div>

              <!-- Audio -->
              <div v-if="form.type == 'audio'">
                <el-form-item label="Archivo audio .mp3">
                  <el-upload
                    name="file"
                    action="#"
                    :http-request="postAudio"
                    :before-upload="beforeAudioUpload"
                    :on-success="handleAvatarSuccessAudio"
                    >
                    <el-button slot="trigger" size="small" type="primary">Selecciona un archivo .mp3</el-button>
                    <!-- <i class="el-icon-upload"></i> -->
                    <!-- <div class="el-upload__text">Suelta tu Archivo aquí o <em>haz clic para cargar</em></div> -->
                  </el-upload>
                </el-form-item>
              </div>
              <el-form-item label="Fecha de publicación">
                <ValidationProvider name="Fecha de publicación" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.date"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onStore"
                type="primary"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Crear</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { storeAbout } from '@/api/about'
import { uploadImageFile } from '@/api/image'
import { uploadFile } from '@/api/fileUpload'

export default {
  data () {
    return {
      // type: '',
      form: {
        title: ''
        // description: '',
        // content: '',
        // image: '',
        // thumb: '',
        // video: '',
        // audio: '',
        // date: '',
        // file: '',
        // type: ''
      },
      optionType: [{
        value: 'text',
        label: 'Texto'
      }, {
        value: 'audio',
        label: 'Audio'
      }, {
        value: 'video',
        label: 'Video'
      }],
      loading: false
    }
  },
  created () {
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'img/about')
      formData.append('width', '750')
      formData.append('height', '420')
      formData.append('title', 'about')
      formData.append('alt', 'imagen-about')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async onStore () {
      await storeAbout(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Se ha guardado con exito!',
            type: 'success'
          })
          this.$router.push({ name: 'about' })
        })
        .catch(this.responseCatch)
    },
    handleAvatarSuccess (res, file) {
      // this.form.image = 'https://safeapp.webdecero.dev/' + res
      this.form.image = res.path
      this.form.thumb = res.pathThumb
      // console.log(this.form.image)
    },
    async postFile (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('alt', 'Avigrupo-file')
      uploadFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response)
        this.$message({
          showClose: true,
          message: 'Se ha cargado el archivo!',
          type: 'success'
        })
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessFile (res, file) {
      this.form.file = res.path
      console.log(res.path)
      // console.log(this.form.header)
    },
    async postAudio (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('alt', 'Avigrupo-file')
      uploadFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response)
        this.$message({
          showClose: true,
          message: 'Se ha cargado el archivo!',
          type: 'success'
        })
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessAudio (res, file) {
      this.form.audio = res.path
      // this.invalid = true
      // console.log(res.path)
      // console.log(this.form.header)
    },
    beforeFIleUpload (file) {
      // console.log(file)
      const isMP3 = file.type === 'application/pdf'
      const isLt2M = file.size / 1024 / 1024 < 10

      if (!isMP3) {
        this.$message.error('¡El archivo debe estar en formato pdf!')
      }
      if (!isLt2M) {
        this.$message.error('¡El audio excede los 10MB!')
      }
      return isMP3 && isLt2M
    },
    beforeAudioUpload (file) {
      // console.log(file)
      const isMP3 = file.type === 'audio/mpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isMP3) {
        this.$message.error('¡El audio debe estar en formato mp3!')
      }
      if (!isLt2M) {
        this.$message.error('¡El audio excede los 2MB!')
      }
      return isMP3 && isLt2M
    }
  }
}
</script>
